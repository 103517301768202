.footer_padding {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    padding-bottom: 5%;
}

.footer_padding{
    background-color: var(--white-color);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 50px;
    padding-bottom: 5%;
}

.columns:after{
    content: "";
    display: table;
    clear: both;
}

.info-column{
    float: left;
    width: 33%;
}

.info_title{
    font-family: Space Mono;
    font-weight: bold;
    color: var(--black-color);
    padding-bottom: 4%;
}

.info_link{
    font-family: Space Mono;
    font-weight: normal;
    color: var(--black-color);
    text-decoration: underline;
}

.info_else{
    font-family: Space Mono;
    font-weight: normal;
    color: var(--black-color);
}

.info_padding{
    padding-bottom: 6%;
}

.info-name_title{
    font-family: Gopher;
    font-size: 200%;
    color: var(--black-color);
}

@media screen and (min-width: 1200px) {
    .info_title{
        font-size: 1.25vw;
    }
    .info_link{
        font-size: 1vw;
    }
    .info_else{
        font-size: 1vw;
    }
}

@media screen and (max-width: 1199px) {
    .info_title{
        font-size: 1.75vw;
    }
    .info_link{
        font-size: 1.5vw;
    }
    .info_else{
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 849px) {
    .info_title{
        font-size: 2.75vw;
    }
    .info_link{
        font-size: 2.5vw;
    }
    .info_else{
        font-size: 1.95vw;
    }
}
