.play_page {
    background-color: var(--black-color);
    color: var(--white-color);
}

.play_video{
    height: 100vh;
    width: 100vw;
}

.play_info_pad{
    padding-top: 1%;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 2%;
    color: white;
}

.play_info_back{
    font-family: Gopher;
    font-size: 2vh;
    cursor: pointer;
    width: 5%;
    background-color: var(--white-color);
    color: var(--black-color);
    text-align: center;
    padding: 0.5%;
    border-radius: 5px;
}

.play_info_back:hover{
    text-decoration: underline;
    background-color: lightgrey;
}

.play_info_name{
    font-family: Space Mono;
    font-size: 6vh;
}

.play_info_info{
    font-family: Space Mono;
    font-size: 3vh;
    padding-bottom: 2%;
    padding-top: 1%;
}

.play_info_specs{
    font-family: Space Mono;
    font-size: 2vh;
}