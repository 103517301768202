.shorts_padding{
    padding-top: 15vh;
    padding-left: 3vw;
    padding-right: 3vw;
    width: 100vw;
}

.short_grid{
    display: inline-flex;
    padding-bottom: 2vh;
}

.short_video_thumb_pad{
    width: 21.3vw;
    height: 20vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.short_video_thumb{
    width: 21.3vw;
    height: 20vh;
    object-fit: cover;
    cursor: pointer;
    border-radius: 0.4vw;
}
.short_video_thumb:hover{
    width: 22.365vw;
    height: 21vh
}

.short_title{
    padding-left: 1vw;
    font-family: Gopher;
    font-size: 2.5vw;
    padding-bottom: 5vh;
}