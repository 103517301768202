.navbar {
    height: 13vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7%;
    transition: background-color 0.3s ease;
    z-index: 1000;
  }
  
  .navbar img {
    height: 13vh;
    width: auto;
  }
  
  .nav-links {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
  }
  
  @media (min-width: 786px){

    .nav-link {
      margin: 0 20px;
      font-size: 1.5vw;
      font-family: Gopher;
      cursor: pointer;
    }
    .nav-link:hover{
      text-decoration: underline;
    }
}

  @media (max-width: 786px){
    .nav-link{
      margin: 0 20px;
      font-size: 2.5vw;
      font-family: Gopher;
      cursor: pointer;
    }
    .nav-link:hover{
      text-decoration: underline;
    }
  }
  
  /* Navbar styles when scrolling */
  .navbar.scrolling {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .logo{
    cursor: pointer;
  }